import { useStripeAccountInfoQuery } from "~graphql/generated/graphql"
import { useAuth } from "~utils/hooks/use-auth"
import { getGettingStartedUrlWithDomain } from "~utils/helpers/navigation-helpers"

export default function useStripeAccountInfo() {
    const url = getGettingStartedUrlWithDomain()
    const { isAuthenticated } = useAuth()

    const {
        data,
        loading: isLoading,
        error,
    } = useStripeAccountInfoQuery({
        skip: !isAuthenticated,
        variables: {
            onboardLinkUrls: {
                refreshUrl: url,
                returnUrl: url,
            },
        },
        fetchPolicy: "network-only",
    })

    const stripeAccountInfo = data?.stripeAccountInfo ?? null

    return { stripeAccountInfo, isLoading, error }
}
