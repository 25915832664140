import React from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import {
    Box,
    Flex,
    Text,
    Center,
    Button,
    Spacer,
    Divider,
    useBreakpointValue,
} from "@chakra-ui/react"
import { BsChevronDoubleLeft } from "react-icons/bs"

import TextLogo from "~components/shared/header/logo"
import IconLogo from "~components/shared/header/icon-logo"
import SidebarContent from "~components/shared/sidebar/sidebar-content"
import PermissionsProvider from "~components/shared//permissions/permissions-provider"
import packageJson from "~package"

interface Props {
    readonly isCollapsed: boolean
    readonly setIsCollapsed: (value: boolean) => void
}

export default function Sidebar({ isCollapsed, setIsCollapsed }: Props) {
    const width = useBreakpointValue({ base: "250px" })

    return (
        <Flex
            height="100%"
            width={isCollapsed ? "72px" : width}
            direction="column"
            transition="width .3s, background-color .2s, display .3s"
            position="relative"
        >
            <Center minH="64px">
                {isCollapsed ? <IconLogo boxSize="52px" /> : <TextLogo />}
            </Center>
            <Divider />
            <Box overflow="auto">
                <PermissionsProvider>
                    <SidebarContent isCollapsed={isCollapsed} />
                </PermissionsProvider>
            </Box>
            <Spacer />
            <Box display={["none", "initial"]}>
                <Button
                    width="full"
                    borderRadius={0}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                >
                    <Box
                        transform={isCollapsed ? "rotate(-180deg)" : ""}
                        transition="transform .3s"
                    >
                        <BsChevronDoubleLeft strokeWidth={0.5} />
                    </Box>
                </Button>
                <Divider />

                <Text textAlign="center" color="gray.400" fontSize="xs">
                    v{packageJson.version}
                </Text>
            </Box>
        </Flex>
    )
}
