import { navigate } from "gatsby"
import { makeVar } from "@apollo/client"

import { ROUTES } from "~config/constants"

const DEFAULT_RETURN_ROUTE = ROUTES.HOME

// Using reactive var so that this is global state.
// Exported only for testing purposes.
export const returnRouteVar = makeVar<string>(DEFAULT_RETURN_ROUTE)

export default function useRedirectReturnRoute() {
    const setReturnRoute = (route: string) => returnRouteVar(route)
    const goToReturnRoute = () => {
        void navigate(returnRouteVar())

        setReturnRoute(DEFAULT_RETURN_ROUTE)
    }

    return { setReturnRoute, goToReturnRoute }
}
