// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type { BoxProps } from "@chakra-ui/react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Box } from "@chakra-ui/react"
import React from "react"

interface ConditionalBoxProps extends BoxProps {
    readonly showIf: boolean | null | undefined
}

export default function ConditionalBox({
    showIf,
    ...props
}: ConditionalBoxProps) {
    return <Box display={showIf ? "block" : "none"} {...props} />
}
