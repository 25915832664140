import type { PropsWithChildren } from "react"
import React from "react"
import fetch from "isomorphic-fetch"
import {
    ApolloProvider as Provider,
    ApolloClient,
    HttpLink,
    InMemoryCache,
    type TypePolicies,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"

import { useAccessToken } from "~utils/hooks/use-auth"
import { CustomHeaders, CLIENT_TYPE } from "~config/constants"
import { getVersionNumber } from "~utils/helpers/helpers"

export default function ApolloProvider({
    children,
}: Readonly<PropsWithChildren>) {
    const accessToken = useAccessToken()

    const version = getVersionNumber()
    const httpLink = new HttpLink({
        uri: process.env.GATSBY_API_URL,
        fetch,
        headers: {
            [CustomHeaders.CLIENT_NAME]: CLIENT_TYPE,
            [CustomHeaders.CLIENT_VERSION]: version,
        },
    })

    const authLink = setContext((_, { headers }) => {
        if (!accessToken) return { headers }

        return {
            headers: {
                ...headers,
                authorization: accessToken ? `Bearer ${accessToken}` : "",
            },
        }
    })

    const client = new ApolloClient({
        name: CLIENT_TYPE,
        version: version,
        link: authLink.concat(httpLink),
        cache: new InMemoryCache({ typePolicies: getTypePolicies() }),
        credentials: "include",
    })

    return <Provider client={client}>{children}</Provider>
}

export function getTypePolicies(): TypePolicies {
    return {
        // Quote add ons don't have globally unique IDs, they use the same
        // ID as the referenced add on. In order to cache properly, apollo
        // needs to use a combination of id and package_id.
        QuoteAddOn: {
            keyFields: ["id", "package_id"],
        },
    }
}
