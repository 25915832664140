import React from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type { ImageProps } from "@chakra-ui/react"

import Image from "~components/shared/image"
import IconLogoImage from "~images/logos/mb-logo-small.png"

export default function IconLogo(props: ImageProps) {
    return <Image src={IconLogoImage} alt="mallardbay-icon-logo" {...props} />
}
