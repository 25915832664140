import type { PropsWithChildren } from "react"
import React, { createContext, useContext, useEffect, useState } from "react"

import { IS_IN_APP_BROWSER_QUERY_PARAM } from "~config/constants"
import { getUrlQueryParam } from "~utils/helpers/helpers"

interface IInAppBrowserContext {
    isInAppBrowser: boolean
}

const InAppBrowserContext = createContext<IInAppBrowserContext>({
    isInAppBrowser: false,
})

export function InAppBrowserProvider({
    children,
}: Readonly<PropsWithChildren>) {
    const value = useInAppBrowserValues()

    return (
        <InAppBrowserContext.Provider value={value}>
            {children}
        </InAppBrowserContext.Provider>
    )
}

export function useInAppBrowserContext(): IInAppBrowserContext {
    return useContext(InAppBrowserContext)
}

// Not intended for use outside of UserProvider.
function useInAppBrowserValues(): IInAppBrowserContext {
    const [isInAppBrowser, setIsInAppBrowser] = useState(false)
    const isInAppBrowserParam = getUrlQueryParam(IS_IN_APP_BROWSER_QUERY_PARAM)

    useEffect(() => {
        setIsInAppBrowser(!!isInAppBrowserParam)
    }, [isInAppBrowserParam])

    return {
        isInAppBrowser,
    }
}
