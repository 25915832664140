import { makeVar, useReactiveVar } from "@apollo/client"
import { useAuth0 } from "@auth0/auth0-react"
import { useState, useEffect } from "react"

const accessTokenVar = makeVar<string | null>(null)

export function useAuth() {
    const [error, setError] = useState<Error>()

    const {
        isAuthenticated: hasBeenAuthenticated,
        isLoading: isLoadingAuth0,
        getAccessTokenSilently,
        loginWithRedirect,
        logout,
        user: authUser,
    } = useAuth0()

    const accessToken = useReactiveVar(accessTokenVar)

    useEffect(() => {
        if (!hasBeenAuthenticated || Boolean(accessToken)) return

        void getAndSetAccessToken({ getAccessTokenSilently, setError })
    }, [hasBeenAuthenticated, getAccessTokenSilently, accessToken])

    const isAuthenticated = hasBeenAuthenticated && Boolean(accessToken)
    const isLoadingToken = !isAuthenticated && hasBeenAuthenticated
    const isLoading = isLoadingToken || isLoadingAuth0

    const wrappedLogout = () => {
        void logout({
            logoutParams: {
                returnTo: process.env.GATSBY_MB_ROOT_URL,
            },
        })
        accessTokenVar(null)
    }

    return {
        accessToken,
        authUser,
        isLoading,
        error,
        isAuthenticated,
        hasBeenAuthenticated,
        loginWithRedirect,
        logout: wrappedLogout,
    }
}

export function useAccessToken(): string | null {
    const { accessToken } = useAuth()

    return accessToken
}

async function getAndSetAccessToken({
    getAccessTokenSilently,
    setError,
}: {
    getAccessTokenSilently: () => Promise<string>
    setError: (error: Error) => void
}) {
    try {
        const accessToken = await getAccessTokenSilently()

        accessTokenVar(accessToken)
    } catch (error) {
        setError(error as Error)
    }
}
