import React from "react"

import Layout from "~components/shared/layout"

interface Props {
    readonly element: React.ReactNode
}

export default function WrapPageElement({ element }: Props) {
    return <Layout>{element}</Layout>
}
