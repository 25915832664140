// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type { ImageProps, SkeletonProps } from "@chakra-ui/react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Image as ImageOg, Skeleton } from "@chakra-ui/react"
import type { SyntheticEvent } from "react"
import React, { useEffect, useMemo, useRef, useState } from "react"

import {
    PLACEHOLDER_IMG_SRC,
    TEST_IDS,
    UPLOAD_CARE_BASE_DOMAIN,
} from "~config/constants"
import { resizeUrl } from "~utils/helpers/upload-care-helpers"

interface Props extends ImageProps {
    readonly skeletonStyles?: SkeletonProps
}

// Modeled after shared/image.tsx in web. Keep them aligned so we can unify when we use a shared component lib
export default function Image({
    src,
    onLoad,
    skeletonStyles = {},
    ...props
}: Props) {
    const imgRef = useRef<HTMLImageElement>(null)
    const [isImageLoaded, setIsImageLoaded] = useState(false)
    const isUploadCareImage = useMemo(
        () => src?.includes(UPLOAD_CARE_BASE_DOMAIN),
        [src]
    )

    const srcWithPlaceholder = src || PLACEHOLDER_IMG_SRC
    const resizedSrc = isUploadCareImage
        ? resizeUrl(srcWithPlaceholder)
        : srcWithPlaceholder
    const wrappedOnLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
        setIsImageLoaded(true)

        if (onLoad) onLoad(event)
    }

    const display = isImageLoaded ? "initial" : "none"

    // https://stackoverflow.com/a/59153135
    useEffect(() => {
        if (imgRef.current?.complete && !isImageLoaded) {
            setIsImageLoaded(true)
        }
    }, [src])

    return (
        <ImageOg
            data-testid={TEST_IDS.IMAGE}
            ref={imgRef}
            src={resizedSrc}
            onLoad={wrappedOnLoad}
            fallback={
                <Skeleton
                    data-testid={TEST_IDS.IMAGE_SKELETON}
                    {...skeletonStyles}
                />
            }
            display={display}
            {...props}
        />
    )
}
