import React from "react"
import { Link } from "gatsby"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type { ImageProps } from "@chakra-ui/react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { useColorModeValue } from "@chakra-ui/react"

import LogoDark from "~images/logos/mb-logo-dark.svg"
import LogoLight from "~images/logos/mb-logo-light.svg"
import Image from "~components/shared/image"

interface Props extends ImageProps {
    readonly shouldRedirect?: boolean
}

export default function Logo({ shouldRedirect, ...props }: Props) {
    const logo = useColorModeValue(LogoDark, LogoLight)

    const imageElement = (
        <Image src={logo} alt="mallardbay-logo" width="180px" {...props} />
    )

    return shouldRedirect ? <Link to="/">{imageElement}</Link> : imageElement
}
