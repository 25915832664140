// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Box, HStack, Text } from "@chakra-ui/react"
import React from "react"

import { useUser } from "~config/context-providers/user-provider"
import { getUserName } from "~utils/helpers/helpers"
import ProfileTag from "~components/shared/profile-menu/profile-tag"

export default function ViewingAsDisplay() {
    const { user } = useUser()

    return (
        <Box py={1} px={3}>
            <HStack justify="space-between">
                <Text fontSize="lg" fontWeight="bold" noOfLines={1}>
                    {getUserName(user)}
                </Text>
                <ProfileTag />
            </HStack>
            <Text fontSize="xs" noOfLines={1}>
                {user?.email}
            </Text>
        </Box>
    )
}
