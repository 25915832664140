import React from "react"
import type { InboxTheme } from "@trycourier/react-inbox"
import { Inbox } from "@trycourier/react-inbox"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { useTheme } from "@chakra-ui/react"
import type { CustomTheme } from "@mallardbay/lib-react-components"

import { useAuth } from "~utils/hooks/use-auth"

export default function CourierInbox() {
    const { isAuthenticated } = useAuth()

    const brandColors = useBrandColors()

    if (!isAuthenticated) {
        return null
    }

    return (
        <Inbox
            theme={getInboxTheme()}
            brand={brandColors}
            // hides footer saying 'provided by courier'
            renderFooter={() => null}
        />
    )
}

function getInboxTheme(): InboxTheme {
    return {
        // for mobile view
        // 64px is the height of the header
        // zIndex needs to be high to be above some of the buttons on homepage
        container: {
            zIndex: 1000,
            top: "64px",
        },
    }
}

function useBrandColors() {
    const { colors } = useTheme<CustomTheme>()

    return {
        colors: {
            primary: colors.custom.brightOrange,
            secondary: colors.custom.lightGray,
            tertiary: colors.blackAlpha[100],
        },
    }
}
