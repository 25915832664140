import React, { useContext, useState } from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import {
    Box,
    Text,
    Button,
    Divider,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Highlight,
    Center,
    Spinner,
    Avatar,
    Tag,
    useColorModeValue,
} from "@chakra-ui/react"
import { FiSearch } from "react-icons/fi"

import { useDebounce } from "~utils/hooks/use-debounce"
import type { UserFieldsFragment } from "~graphql/generated/graphql"
import {
    useSearchUsersQuery,
    useStartImpersonationMutation,
    useStopImpersonationMutation,
} from "~graphql/generated/graphql"
import { UserContext } from "~config/context-providers/user-provider"

interface Props {
    readonly isOpen: boolean
    readonly onClose: () => void
}

export default function ImpersonationModal({ isOpen, onClose }: Props) {
    const { user: currentUser, isImpersonating } = useContext(UserContext)

    const [query, setQuery] = useState("")
    const debouncedQuery = useDebounce(query, 500).trim()

    const { loading, data } = useSearchUsersQuery({
        variables: {
            text: debouncedQuery,
            page: 0,
            size: 8,
        },
        skip: !isOpen,
    })

    const handleClose = () => {
        setQuery("")
        onClose()
    }

    const users = [...(data?.searchUsers.results || [])].filter(
        (user) => user.id !== currentUser?.id
    )
    const total = data?.searchUsers.total || 0

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent rounded="xl">
                <ModalHeader px={4} py={3}>
                    <HStack>
                        <FiSearch fontSize="25px" />
                        <Input
                            variant="unstyled"
                            value={query}
                            onChange={(event) => setQuery(event.target.value)}
                            placeholder={`Search ${total} users`}
                        />
                    </HStack>
                </ModalHeader>
                <Divider />
                <ModalBody px={2}>
                    {isImpersonating && currentUser && (
                        <UserImpersonationItem
                            query={query}
                            user={currentUser}
                            isViewing
                        />
                    )}
                    {loading && (
                        <Center h="120px">
                            <Spinner size="sm" />
                        </Center>
                    )}
                    {!loading && users.length === 0 && (
                        <Center h="120px" fontWeight="bold">
                            No users found
                        </Center>
                    )}
                    {users.map((user) => (
                        <UserImpersonationItem
                            key={user.id}
                            user={user}
                            query={query}
                        />
                    ))}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

interface UserImpersonationItemProps {
    readonly user: UserFieldsFragment
    readonly query: string
    readonly isViewing?: boolean
}

function UserImpersonationItem({
    user,
    query,
    isViewing = false,
}: UserImpersonationItemProps) {
    const [starImpersonation, { loading: isStarting }] =
        useStartImpersonationMutation({
            variables: {
                actsAsUserId: user.id,
            },
            onCompleted: () => {
                window.location.href = "/"
            },
        })

    const [stopImpersonation, { loading: isStopping }] =
        useStopImpersonationMutation({
            onCompleted: () => {
                window.location.href = "/"
            },
        })

    const activeBgColor = useColorModeValue("brand.50", "brand.800")

    return (
        <HStack
            py={2}
            px={2}
            spacing={3}
            rounded="xl"
            bgColor={isViewing ? activeBgColor : "initial"}
        >
            <Avatar
                shadow="lg"
                src={user.avatar_url ?? ""}
                name={`${user.first_name} ${user.last_name}`}
            />
            <Box>
                <HStack>
                    <Text fontSize="15px" noOfLines={1} fontWeight="bold">
                        <Highlight query={query} styles={{ bg: "yellow.200" }}>
                            {`${user.first_name} ${user.last_name}`}
                        </Highlight>
                    </Text>
                    {isViewing && (
                        <Tag size="sm" colorScheme="brand" fontWeight="bold">
                            Viewing
                        </Tag>
                    )}
                </HStack>
                <Text noOfLines={1} fontSize="xs">
                    <Highlight query={query} styles={{ bg: "yellow.200" }}>
                        {user.email}
                    </Highlight>
                </Text>
            </Box>
            <Spacer />
            {isViewing ? (
                <Button
                    size="sm"
                    colorScheme="red"
                    isLoading={isStopping}
                    onClick={() => stopImpersonation()}
                >
                    Exit
                </Button>
            ) : (
                <Button
                    size="sm"
                    isLoading={isStarting}
                    onClick={() => starImpersonation()}
                >
                    View as
                </Button>
            )}
        </HStack>
    )
}
