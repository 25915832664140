import React from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { IconButton } from "@chakra-ui/react"
import { FiMenu } from "react-icons/fi"

type Props = {
    readonly onClick: () => void
}

export default function SidebarDrawerButton({ onClick }: Props) {
    return (
        <IconButton
            rounded="full"
            variant="outline"
            aria-label="menu-button"
            icon={<FiMenu size={24} strokeWidth="2.5px" />}
            onClick={onClick}
            display={["flex", "flex", "none"]}
        />
    )
}
