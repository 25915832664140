import { useEffect } from "react"
import { useMatch } from "@reach/router"
import { navigate } from "gatsby"

import { useUser } from "~config/context-providers/user-provider"
import useRedirectReturnRoute from "~utils/hooks/use-redirect-return-route"
import { ROUTES } from "~config/constants"

export default function useOnboardingRedirects() {
    const { setReturnRoute } = useRedirectReturnRoute()
    // Our current onboarding form only asks for basic info, so this should be a good check.
    // If we have a user without a name, and are not already in the onboarding page,
    // redirect to the onboarding page.
    const shouldRedirectToOnboarding = useShouldRedirectToOnboarding()

    useEffect(() => {
        // If we're about to redirect, set the return route.
        if (shouldRedirectToOnboarding) {
            const returnRoute = `${window.location.pathname}${window.location.search}`

            setReturnRoute(returnRoute)
            void navigate(ROUTES.ONBOARD)
        }
    }, [shouldRedirectToOnboarding])

    return { isRedirecting: shouldRedirectToOnboarding }
}

function useShouldRedirectToOnboarding() {
    const { user } = useUser()
    const isOnboardingRoute = useMatch(ROUTES.ONBOARD)

    // If there's no user, or
    // if we're already redirecting, or
    // nothing to do.
    if (!user || isOnboardingRoute) return false

    // By now we have a user

    // Do not redirect admins
    if (user.is_admin) return false

    // If user has no first name, redirect
    return !user.first_name
}
