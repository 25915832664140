import React, {
    useMemo,
    createContext,
    useContext,
    type PropsWithChildren,
} from "react"

import useOutfitter from "~utils/hooks/use-outfitter"
import { useUser } from "~config/context-providers/user-provider"

interface PermissionsContextType {
    shouldShowActions: boolean
    shouldShowEarnings: boolean
    isLoading: boolean
    error?: Error | null
}

const PermissionsContext = createContext<PermissionsContextType>({
    shouldShowActions: false,
    shouldShowEarnings: false,
    isLoading: false,
    error: null,
})

interface Props extends PropsWithChildren {
    readonly outfitterId?: string
}

// If an outfitterId is passed, the outfitter is fetched to see if the user is an
// owner. If no outfitterId is passed (for global pages, not specific to a single outfitter),
// permissions will be based on whether the user is the owner of all outfitters they are.
// attached to (decided by backend). This is the same permission logic as being used on mobile.
export default function PermissionsProvider({ outfitterId, children }: Props) {
    const { isOwner, isLoading: isLoadingUser } = useUser()
    const {
        isOutfitterOwner,
        isLoading: isLoadingOutfitter,
        error,
    } = useOutfitter({ id: outfitterId })

    // Right now, earnings and actions permissions are both based on the same thing.
    // Using two variables in case this changes.
    const shouldShowActions = isOutfitterOwner || isOwner
    const shouldShowEarnings = shouldShowActions

    // We don't have to wait for the outfitter to load if the user is global owner.
    const isLoading = isLoadingUser || (!isOwner && isLoadingOutfitter)

    const value = useMemo(
        () => ({
            shouldShowActions,
            shouldShowEarnings,
            isLoading,
            error,
        }),
        [shouldShowActions, shouldShowEarnings, isLoading, error]
    )

    return (
        <PermissionsContext.Provider value={value}>
            {children}
        </PermissionsContext.Provider>
    )
}

export const usePermissions = () => useContext(PermissionsContext)
