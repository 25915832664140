import React, { useContext } from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type { BoxProps } from "@chakra-ui/react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import {
    Box,
    Text,
    HStack,
    SlideFade,
    IconButton,
    ButtonGroup,
    useDisclosure,
} from "@chakra-ui/react"
import { CloseIcon } from "@chakra-ui/icons"
import { useMatch } from "@reach/router"
import { FiUsers } from "react-icons/fi"

import { useStopImpersonationMutation } from "~graphql/generated/graphql"
import { UserContext } from "~config/context-providers/user-provider"
import { useAuth } from "~utils/hooks/use-auth"

import ImpersonationModal from "./impersonation-modal"

export default function ImpersonationBox(boxProps: Readonly<BoxProps>) {
    const { authUser } = useAuth()
    const { user, isImpersonating } = useContext(UserContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const matchAdmin = useMatch("/admin/*")
    const matchReporting = useMatch("/reporting/*")

    const [stopImpersonation, { loading }] = useStopImpersonationMutation({
        onCompleted: () => {
            window.location.pathname = ""
        },
    })

    // Hide if we're on an admin only page, no user, or not impersonating.
    if (matchAdmin || matchReporting || !user || !isImpersonating) return null

    return (
        <SlideFade in>
            <Box
                mx={2}
                my={6}
                pos="relative"
                rounded="full"
                bgColor="brand.800"
                backdropFilter="blur(10px)"
                {...boxProps}
            >
                <HStack py={2} px={4} spacing={4} color="white">
                    <Box fontSize={["xs", "sm"]} flex={1} zIndex={1}>
                        <Text noOfLines={1}>
                            Logged in as {authUser?.name}&nbsp;
                            <strong>({authUser?.email})</strong>
                        </Text>

                        <Text noOfLines={1}>
                            Now viewing&nbsp;
                            <u>
                                {user.first_name} {user.last_name}
                            </u>
                            &nbsp;
                            <strong>({user.email})</strong>
                        </Text>
                    </Box>
                    <ButtonGroup size="sm" spacing={1}>
                        <IconButton
                            rounded="full"
                            colorScheme="green"
                            aria-label="Switch impersonation"
                            icon={<FiUsers fontSize="18px" />}
                            onClick={onOpen}
                        />
                        <IconButton
                            rounded="full"
                            colorScheme="red"
                            aria-label="Stop impersonation"
                            icon={<CloseIcon />}
                            isLoading={loading}
                            onClick={() => stopImpersonation()}
                        />
                    </ButtonGroup>
                </HStack>

                <ImpersonationModal isOpen={isOpen} onClose={onClose} />
            </Box>
        </SlideFade>
    )
}
