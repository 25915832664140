import React from "react"

import AuthProvider from "~config/context-providers/auth-provider"
import ApolloProvider from "~config/context-providers/apollo-provider"
import { ReactQueryProvider } from "~config/context-providers/react-query-provider"
import { UserProvider } from "~config/context-providers/user-provider"
import CourierInboxProvider from "~config/context-providers/courier-inbox-provider"
import { InAppBrowserProvider } from "~config/context-providers/in-app-browser-provider"
import ThemeProvider from "~config/context-providers/theme-provider"

import "swiper/css"
import "react-datepicker/dist/react-datepicker.css"

import "~styles/uploadcare.css"
import "~styles/react-datepicker.css"

interface Props {
    readonly element: React.ReactNode
}

export default function WrapRootElement({ element }: Props) {
    return (
        <ThemeProvider>
            <AuthProvider>
                <ApolloProvider>
                    <ReactQueryProvider>
                        <UserProvider>
                            <InAppBrowserProvider>
                                <CourierInboxProvider>
                                    {element}
                                </CourierInboxProvider>
                            </InAppBrowserProvider>
                        </UserProvider>
                    </ReactQueryProvider>
                </ApolloProvider>
            </AuthProvider>
        </ThemeProvider>
    )
}
