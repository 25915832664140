import { useState, useEffect } from "react"

export function useDebounce<T>(value: T, delay = 300): T {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value)

    useDebounceCallback({ value, callBack: setDebouncedValue, delay })

    return debouncedValue
}

export function useDebounceCallback<TValue>({
    value,
    callBack,
    delay = 300,
}: {
    value: TValue
    callBack: (value: TValue) => void
    delay?: number
}) {
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                callBack(value)
            }, delay)

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler)
            }
        },
        [value, delay] // Only re-call effect if value or delay changes
    )
}
