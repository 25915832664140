import { ONE_MEGABYTE_IN_BYTES } from "~config/constants"

const DEFAULT_WIDTH = 800
const DEFAULT_SIZE_LIMIT_IN_MB = 10

// TODO would be good to create an `UploadCareImage` component
export function resizeUrl(url?: string, width?: number) {
    return `${url}/-/resize/${width ?? DEFAULT_WIDTH}/`
}

export function getMaxFileSize(sizeLimit = DEFAULT_SIZE_LIMIT_IN_MB) {
    return ONE_MEGABYTE_IN_BYTES * sizeLimit
}
