import React, { useMemo } from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type { BoxProps } from "@chakra-ui/react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Box } from "@chakra-ui/react"

interface Props extends BoxProps {
    readonly size?: "sm" | "md" | "lg" | "xl"
}

export default function ResponsiveBlock({ size, ...props }: Props) {
    const maxWidth = useMemo(() => {
        switch (size) {
            case "sm":
                return "768px"
            case "md":
                return "992px"
            case "lg":
                return "1280px"
            case "xl":
                return "1536px"
            default:
                return undefined
        }
    }, [size])

    const mx = size ? "auto" : 0

    return <Box px={[4, 4, 8, 8, 8]} mx={mx} maxW={maxWidth} {...props} />
}
