// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Icon, MenuItem, Spacer, Text, Link } from "@chakra-ui/react"
import React from "react"
import type { IconType } from "react-icons"

interface Props {
    readonly label: string
    readonly icon: IconType
    readonly href?: string
    readonly isExternal?: boolean
    readonly onClick?: () => void
}

export default function IconMenuItem({
    label,
    icon,
    href,
    isExternal,
    onClick,
}: Props) {
    const menuItemProps = href ? { as: Link, href, isExternal } : {}

    return (
        <MenuItem {...menuItemProps} onClick={onClick}>
            <Text>{label}</Text>
            <Spacer />
            <Icon as={icon} />
        </MenuItem>
    )
}
